/* Font Awesome */
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);

body {
  margin: 0;
  margin-top: 85px !important;
  padding: 0;
  background-color: #f1f5f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.copied {
  transition: opacity 300ms ease-out;
}

.copied-visible {
  display: inline;
  opacity: 1;
}

.copied-hidden {
  visibility: none;
  opacity: 0;
}