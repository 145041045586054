.loading-view-container {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1004;
}

.loading-view {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(50, 200, 50, 0.4);
    z-index: 1005;
}